import React from "react";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Footer, { LinkItem } from "@/components/Footer";
import Heading from "@/components/Heading";
import AtSymbolIcon from "@/icons/at-symbol.svg";
import ShareIcon from "@/icons/share.svg";
import PhoneIcon from "@/icons/phone.svg";
import InstagramIcon from "@/icons/instagram.svg";
import TwitterIcon from "@/icons/twitter.svg";
import { ContactUsPageQuery } from "../../graphql-types";

const sections = [
  {
    icon: AtSymbolIcon,
    title: "Email us",
    body: (
      <a className="lowercase link" href="mailto:hello@samanloira.com" target="_blank" rel="noreferrer">
        hello@samanloira.com
      </a>
    ),
  },
  {
    icon: PhoneIcon,
    title: "Call us",
    body: (
      <a className="lowercase link" href="tel:+39 3381088276">
        +39 3381088276
      </a>
    ),
  },
  {
    icon: ShareIcon,
    title: "Share us",
    body: (
      <ul className="flex mt-4 space-x-4">
        <li>
          <a className="link" href="https://www.instagram.com/saman_loira" target="_blank" rel="noreferrer">
            <InstagramIcon className="opacity-80 hover:opacity-100" />
          </a>
        </li>
        <li>
          <a className="link" href="https://twitter.com/LoiraSaman" target="_blank" rel="noreferrer">
            <TwitterIcon className="opacity-80 hover:opacity-100" />
          </a>
        </li>
      </ul>
    ),
  },
];

interface Props {
  data: ContactUsPageQuery;
}

const ContactUsPage = ({ data }: Props) => {
  const { headerLinks, footerLinks } = data.global || {};

  return (
    <Layout
      scroll="default"
      header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}
      hasTopSpacing
    >
      <Seo title="Contact us" />
      <Layout.Section>
        <div className="container">
          <div className="grid md:grid-cols-3 text-cod-gray gap-16">
            {sections.map(({ icon: Icon, title, body }, key) => (
              <div key={key} className="flex flex-col items-center">
                <Icon className="w-10 h-10 md:mb-4" />
                <Heading level={2}>{title}</Heading>
                <div className="text-lg">{body}</div>
              </div>
            ))}
          </div>
        </div>
      </Layout.Section>
      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default ContactUsPage;

export const query = graphql`
  query ContactUsPage {
    global: dataJson {
      ...Global
    }
  }
`;
